<template>
    <div>
        <div class="text-center mt-4">
            <v-btn :to="{ name: 'login', query: { targetUrl: $route.path } }" class="mr-3" x-large>
                <div>
                    Yes
                    <div class="text-caption text-lowercase">take me to login</div>
                </div>
            </v-btn>
            <v-btn :to="{ name: 'signup-options' }" x-large>
                <div>
                    No
                    <div class="text-caption text-lowercase">set up my account</div>
                </div>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignupHome',
    data() {
        return {}
    },
    created() {},
    methods: {},
    computed: {},
    watch: {},
    updated() {}
}
</script>
